@tailwind base;
@tailwind components;
@tailwind utilities;


ba { color: rgb(0, 116, 204); }
g { color: rgb(41, 106, 84); }
bb { color: rgb(36, 103, 134); }
ga { color: rgb(108, 117, 125); }
bc { color: rgb(0, 33, 71); }


body {
  .grecaptcha-badge {
    visibility: hidden;
  }

  .bg-bluewood {
    --tw-bg-opacity: 1;
    background-color: rgb(39 60 73 / var(--tw-bg-opacity));
  }

  .fill-bluewood {
    fill: #273c49;
  }

  .bg-green {
    /* background-color:  #3c8d8e; */
    background: linear-gradient(to right, #3c8d8e, #81a3da);
    width: 100%;
  }

  .fill-theel {
    fill: rgb(92 198 199);
  }

  .bg-theel {
    background-color: rgb(92 198 199);
  }

  .fade {
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
    transition-property: opacity;
    transition-duration: 2000ms;
  }

  .fade-in {
    opacity: 1;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
